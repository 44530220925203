import angular from 'angular';
import fzpCPerson from './fzpCPerson';
import fzpCFounder from './fzpCFounder';
import fzpCFounderCompany from './fzpCFounderCompany';
import fzpSvcFounderCompanyEdit from './fzpSvcFounderCompanyEdit';
import fzpSvcPersonEdit from './fzpSvcPersonEdit';
import fzpCBeneficiaries from './fzpCBeneficiaries';
import fzpCFounders from './fzpCFounders';

const moduleName = 'com.farzoom.pear.fzpCPerson';

angular.module(moduleName, [
        'com.farzoom.pear.cAttributes'
    ])
    .service('fzpSvcFounderCompanyEdit', fzpSvcFounderCompanyEdit)
    .service('fzpSvcPersonEdit', fzpSvcPersonEdit)
    .directive('fzpCPerson', fzpCPerson)
    .directive('fzpCFounder', fzpCFounder)
    .directive('fzpCFounderCompany', fzpCFounderCompany)
    .directive('fzpCBeneficiaries', fzpCBeneficiaries)
    .directive('fzpCFounders', fzpCFounders)

export default moduleName;
