import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.onDelete = function (addressType) {
        const item = (($scope.model || {}).data || []).find(x => x.address.addressTypeRefId === addressType);
        if (!item) return;
        const i = $scope.model.data.indexOf(item);
        $scope.model.data.splice(i, 1);
    };

    my.onEdit = function (addressType) {
        const item = (($scope.model || {}).data || []).find(x => x.address.addressTypeRefId === addressType);

        fzModal.open({
            windowClass: 'fz-c-addresses__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item || Object.assign({}, defaults[addressType], ($scope.model || {}).itemTemplate || {}),
                config: ($scope.model || {}).config || {}
            }
        }).closed.then(result => {
            if (!result) return;

            if (!result.address.result && !result.equalsLegalAddress) {
                // Если не указали адрес, то удаляем его (если он был)
                if (item) {
                    const i = $scope.model.data.indexOf(item);
                    $scope.model.data.splice(i, 1);
                }
            } else {
                // Если указали адрес, добавляем его (или заменяем, если он уже был)
                if (item) {
                    const index = $scope.model.data.indexOf(item);
                    if (index > -1) $scope.model.data[index] = result;
                } else {
                    if (!$scope.model) $scope.model = {};
                    if (!$scope.model.data) $scope.model.data = [];
                    $scope.model.data.push(result);
                }
            }
        });
    };

    my.getAddress = function (addressType) {
        if (!$scope.model || !$scope.model.data) return null;

        return $scope.model.data.find(x => x.address.addressTypeRefId === addressType) || defaults[addressType];
    }

    const defaults = {
        'legalAddress': {
            address: {
                addressTypeRefId: 'legalAddress'
            }
        },
        'actualAddress': {
            address: {
                addressTypeRefId: 'actualAddress'
            }
        },
        'postalAddress': {
            address: {
                addressTypeRefId: 'postalAddress'
            }
        },
    }
}];

export default ctrl;