import 'babel-polyfill';
import angular from 'angular';
import CamSDK from 'cam-sdk';
import 'oclazyload';

import './components';
import './forms';
import './services';
import './tabs';
import './task-forms';

import './utils/polyfills';


angular.module('ext.app', [
    'oc.lazyLoad',
    'ext.cmps',
    'ext.forms',
    'ext.svcs',
    'ext.tabs',
    'ext.task-forms'
]).config(['$anchorScrollProvider', function ($anchorScrollProvider) {
    // HACK: fix for ng-include.
    // ngIncludeDirective requires $anchorScroll. When $anchorScroll is injected (with DI) it always scrolls window to top (BUG in $anchorScroll maybe)
    $anchorScrollProvider.disableAutoScrolling();
}]).config(['fzFilesConfigProvider', function (fzFilesConfigProvider) {
    fzFilesConfigProvider.setMaxSizeInBytes(10 * 1024 * 1024);
}]).run(['$ocLazyLoad', function ($ocLazyLoad) {
    configureCamSDKClient = function (camAPI) {
        var Task = camAPI.resource('task');
        if (Task.isPatchedForMoos) return;
        Task.isPatchedForMoos = true;

        var taskIdToProcessDefinitionKeyMap = {};

        // Monkey patch for task form initialization. Trying to load required modules
        var orig_taskForm = Task.form;
        Task.form = function (taskId, cb) {
            var processDefinitionKey = taskIdToProcessDefinitionKeyMap[taskId];
            delete taskIdToProcessDefinitionKeyMap[taskId];

            return orig_taskForm.call(this, taskId, function (taskFormErr, taskFormData) {
                if (taskFormErr) return cb(taskFormErr, taskFormData);

                // console.log(`Task.form called for taskId: ${taskId}, process: ${processDefinitionKey}, contextPath: ${taskFormData.contextPath}, key: ${taskFormData.key}`);

                if (taskFormData.key.indexOf('{taskId}') > 0) {
                    taskFormData.key = taskFormData.key.replace('{taskId}', taskId);
                }

                loadConfig(taskFormData.contextPath).then(config => {
                    return loadModules(config, processDefinitionKey).then(() => {
                        cb(null, taskFormData)
                    });
                }).catch(configErr => {
                    console.log(`Failed to load config.json for ${processDefinitionKey}. Legacy process maybe?`);
                    cb(null, taskFormData);
                });
            });
        };

        var orig_taskGet = Task.get;
        Task.get = function (taskId, cb) {
            return orig_taskGet.call(this, taskId, function (err, data) {
                var processDefinitionKey = 
                    data &&
                    data._embedded &&
                    data._embedded.processDefinition &&
                    data._embedded.processDefinition[0] &&
                    data._embedded.processDefinition[0].key;
                taskIdToProcessDefinitionKeyMap[taskId] = processDefinitionKey;
                cb(err, data);
            });
        };

        // Monkey patch for start form initialization. Trying to load required modules
        var ProcessDefinition = camAPI.resource('process-definition');
        var orig_processDefinitionStartForm = ProcessDefinition.startForm;
        ProcessDefinition.startForm = function (data, cb) {
            var processDefinitionKey = data.id.split(':')[0];

            orig_processDefinitionStartForm.call(this, data, function (startFormErr, startFormData) {
                if (startFormErr) return cb(startFormErr, startFormData);

                console.log(`ProcessDefinition.startForm called for process: ${processDefinitionKey}, contextPath: ${startFormData.contextPath}`);

                loadConfig(startFormData.contextPath).then(config => {
                    return loadModules(config, processDefinitionKey).then(() => {
                        cb(null, startFormData)
                    });
                }).catch(configErr => {
                    console.log(`Failed to load config.json for ${processDefinitionKey}. Legacy process maybe?`);
                    cb(null, startFormData);
                });
            });
        };

        function legacyLoadConfig() {
            return $ocLazyLoad.load('/cam-ext/web/scripts/ngapp-config.js').then(() => {
                // ngapp is a global variable
                // ngapp.config is initialized inside ngapp-config.js
                const modulesCfg = ngapp.config.modules || {};
                const processConfig = {
                    modules: []
                };

                for (let processDefinitionKey of Object.keys(ngapp.config.modules)) {
                    const m = {
                        moduleName: `process-ui@${processDefinitionKey}`,
                        files: []
                    };
                    const cfg = ngapp.config.modules[processDefinitionKey];
                    if (cfg.path) {
                        if (cfg.path.js) m.files.push(cfg.path.js);
                        if (cfg.path.css) m.files.push(cfg.path.css);
                    }
                    if (m.files.length) {
                        processConfig.modules.push(m)
                    }
                }

                return processConfig;
            }).catch(err => {
                console.log(`Failed to load ngapp-config.js`);
                console.error(err);
                return null;
            });
        }

        function loadConfigFromProcessApplication(contextPath) {
            // Convert jQuery deferred to es6 Promise
            return new Promise((resolve, reject) => {
                $.getJSON(`${contextPath}/config.json`).done(resolve).fail(reject);
            });
        }

        function loadConfig(contextPath) {
            if (!contextPath) {
                return Promise.resolve(null);
            }

            return loadConfigFromProcessApplication(contextPath).catch(err => {
                console.log(`Failed to load config.json for ${contextPath}`);
                return legacyLoadConfig();
            });
        }

        function loadModules(config, processDefinitionKey) {
            const requiredModules = config.modules.filter(m => m.moduleName === 'process-ui' || m.moduleName === `process-ui@${processDefinitionKey}`);
            return Promise.all(requiredModules.map(m => loadModule(m)));
        };

        function loadModule(m) {
            return $ocLazyLoad.load(m.files);
        }
    };

    // This Client is required only to access Task resource
    var camAPI = new CamSDK.Client({
        apiUri: 'stub'
    });
    configureCamSDKClient(camAPI);
}]);

function isFunction(obj) {
    return obj && {}.toString.call(obj) === '[object Function]';
}

export var CamundaSDK = CamSDK;

export var configureCamSDKClient = function () {
    throw new Error("ngapp.configureCamSDKClient() is not ready yet. If must be called after angular.module('ext.app').run is executed");
};

export var init = function (el, deps, cb) {
    if (isFunction(deps)) { // For backwards compatibility
        cb = deps;
        deps = null;
    }

    var rootEl = $('html');
    var isInitialized = angular.element(rootEl).injector();
    if (!isInitialized) {
        angular.bootstrap(rootEl, ['ext.app']);
    }

    if (cb) cb(CamSDK);
};
