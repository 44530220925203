var _ = require('lodash')

const ctrl = ['$scope','$http','$q','fzFilesConfig','$element', function ($scope, $http, $q, fzFilesConfig, $element) {
  const my = this

  my.state = {
    periods: {},
    externalSourceBuh: angular.copy($scope.model.externalSourceBuh),
  }

  my.editedPreviously = false
  my.externalKontur = null
  my.positiveCodes = ['b2110', 'b2310', 'b2320', 'b2340']
  my.negativeCodes = ['b2120', 'b2210', 'b2220', 'b2330', 'b2350']
  my.companyBuhYearFilledCodes = ['1600', '1700', '2400', '1370']
  my.companyBuhYearFilledCodesString = my.companyBuhYearFilledCodes.join(', ')
  my.moneyDivider = 100000
  my.formattedValue = (value) => Number(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  my.removeBrackets = (value) => Number(String(value || '').replace(/\(|\)/g, ''))
  my.removeNegative = (value) => String(value || '').replace(/-/g, '')
  my.removeAll = (value) => {
    let result = value
    result = my.removeBrackets(result)
    result = my.removeNegative(result)
    return result
  }
  my.addBrackets = (value) => '(' + my.removeNegative(value) + ')'
  my.normilizeValue = (value, code) => {
    let result = value
    const isPositiveCode = my.positiveCodes.indexOf(code) > -1
    const isNegativeCode = my.negativeCodes.indexOf(code) > -1
    if (isPositiveCode) {
      result = my.removeAll(result)
    } else {
      if (!isNegativeCode) {
        result = my.removeBrackets(result)
      }
    }
    if (!isNaN(Number(result))) {
      result = Number(result) / my.moneyDivider
      result = my.formattedValue(result)
    }
    if (result[0] === '-' || isNegativeCode) {
      result = my.addBrackets(result)
    }
    return result !== '0' ? result : ''
  }

  my.ref = {
    periods: [],
    taxationType: [
      { value: 'osn', name: 'ОСН' },
      { value: 'usn', name: 'УСН' },
    ],
    taxationAccountingType: [
      { value: 'f1f2', name: 'Баланс' },
      { value: 'kudir', name: 'КУДиР' },
    ],
    taxationUSNType: [
      { value: 'usn6', name: 'доходы (6%)' },
      { value: 'usn15', name: 'доходы - расходы (15%)' },
    ]
  }
  my.ps = []
  my.periodsConfig = {}

  my.isCompanyBuhFilled = (companyBuh) => !!(
    companyBuh &&
    companyBuh.periods &&
    companyBuh.latestPeriod &&
    companyBuh.periods[companyBuh.latestPeriod]
  )

  my.isCompanyBuhYearFilled = (companyBuh) => {
    let isYearPeriodFilled = false
    if (companyBuh && companyBuh.periods) {
      for (let period in companyBuh.periods) {
        const isYearPeriod = period.split('-')[1] === '12'
        const values = companyBuh.periods[period]
        if (isYearPeriod) {
          isYearPeriodFilled = !my.companyBuhYearFilledCodes
            .map(code => !!(values[`b${code}`] || values[`b${code}`] === 0))
            .some(item => !item)
        }
      }
    }
    return isYearPeriodFilled
  }

  const getPeriodName = (dateMonth) => {
    const ps = dateMonth.split('-')
    const year = ps[0]
    const month = ps[1]

    switch (month) {
      case '03': return `I кв ${year}`
      case '06': return `II кв ${year}`
      case '09': return `III кв ${year}`
      case '12': return `IV кв ${year}`
    }

    throw new Error(`Unexpected period: ${dateMonth}`)
  }

  const initPeriods = () => {
    if (!$scope.model || !$scope.model.config || !$scope.model.config.periods) {
      return
    }

    my.ref.periods = []
    let maxLatestPeriod = ''
    for (let ps of $scope.model.config.periods) {
      let latestPeriod = ''
      const periods = []
      for (let p of ps) {
        if (!p) {
          periods.push(null)
        } else {
          if (latestPeriod < p) {
            latestPeriod = p
          }
          periods.push({
            dateMonth: p,
            name: getPeriodName(p)
          })
        }
      }
      if (maxLatestPeriod < latestPeriod) {
        maxLatestPeriod = latestPeriod
      }
      my.periodsConfig[latestPeriod] = periods
      my.ref.periods.push({
        period: latestPeriod,
        name: getPeriodName(latestPeriod)
      })
    }

    $scope.model.latestPeriod =
      $scope.model &&
      $scope.model.latestPeriod &&
      my.ref.periods.some(x => x.period === $scope.model.latestPeriod)
        ? $scope.model.latestPeriod
        : maxLatestPeriod
  }

  const refreshPeriods = () => {
    if (!$scope.model.latestPeriod) {
      my.ps = []
    }
    my.ps = my.periodsConfig[$scope.model.latestPeriod].filter(function (item) {return item !== null})
  }

  $scope.$watch('model.latestPeriod', refreshPeriods)

  const initSummaries = () => {
    var ts = {}
    // БУХГАЛТЕРСКИЙ БАЛАНС
    // АКТИВ
    // I. ВНЕОБОРОТНЫЕ АКТИВЫ
    ts.b1100 = (q) => {
      q = q || {}
      return (
        (my.removeBrackets(q.b1110 || 0)) +
        (my.removeBrackets(q.b1120 || 0)) +
        (my.removeBrackets(q.b1130 || 0)) +
        (my.removeBrackets(q.b1140 || 0)) +
        (my.removeBrackets(q.b1150 || 0)) +
        (my.removeBrackets(q.b1160 || 0)) +
        (my.removeBrackets(q.b1170 || 0)) +
        (my.removeBrackets(q.b1180 || 0)) +
        (my.removeBrackets(q.b1190 || 0))
      )
    }
    // II. ОБОРОТНЫЕ АКТИВЫ
    ts.b1200 = (q) => {
      q = q || {}
      return (
        (my.removeBrackets(q.b1210 || 0)) +
        (my.removeBrackets(q.b1220 || 0)) +
        (my.removeBrackets(q.b1230 || 0)) +
        (my.removeBrackets(q.b1240 || 0)) +
        (my.removeBrackets(q.b1250 || 0)) +
        (my.removeBrackets(q.b1260 || 0))
      )
    }
    // БАЛАНС
    ts.b1600 = (q) => {
      q = q || {}
      return (
        my.removeBrackets(ts.b1100(q)) +
        my.removeBrackets(ts.b1200(q))
      )
    }

    // ПАССИВ
    // III. КАПИТАЛ И РЕЗЕРВЫ
    ts.b1300 = (q) => {
      q = q || {}
      return (
        my.removeBrackets(q.b1310 || 0) +
        my.removeBrackets(q.b1320 || 0) +
        my.removeBrackets(q.b1340 || 0) +
        my.removeBrackets(q.b1350 || 0) +
        my.removeBrackets(q.b1360 || 0) +
        my.removeBrackets(q.b1370 || 0)
      )
    }

    // IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
    ts.b1400 = (q) => {
      q = q || {}
      return (
        my.removeBrackets(q.b1410 || 0) +
        my.removeBrackets(q.b1420 || 0) +
        my.removeBrackets(q.b1430 || 0) +
        my.removeBrackets(q.b1450 || 0)
      )
    }

    // V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
    ts.b1500 = (q) => {
      q = q || {}
      return (
        my.removeBrackets(q.b1510 || 0) +
        my.removeBrackets(q.b1520 || 0) +
        my.removeBrackets(q.b1530 || 0) +
        my.removeBrackets(q.b1540 || 0) +
        my.removeBrackets(q.b1550 || 0)
      )
    }

    // БАЛАНС
    ts.b1700 = (q) => {
      q = q || {}
      return (
        my.removeBrackets(ts.b1300(q)) +
        my.removeBrackets(ts.b1400(q)) +
        my.removeBrackets(ts.b1500(q))
      )
    }

    // Расхождение
    ts.bDiff = (q) => {
      q = q || {}
      return (
        my.removeBrackets(ts.b1700(q)) -
        my.removeBrackets(ts.b1600(q))
      )
    }

    ts.bDiffShow = (ps) => ps.map(p => ts.bDiff(q) !== 0).reduce((a, b) => a || b, false)

    // ОТЧЕТ О ФИНАНСОВЫХ РЕЗУЛЬТАТАХ
    // Валовая прибыль
    ts.b2100 = (q) => {
      q = q || {}

      const b2110 = my.removeBrackets(q.b2110 || 0)
      let b2120 = my.removeBrackets(q.b2120 || 0)

      if (b2120 < 0) {
        b2120 = b2120 * -1
      }

      return b2110 - b2120
    }

    // Прибыль (убыток) от продаж
    ts.b2200 = (q) => {
      q = q || {}

      const b2100 = my.removeBrackets(ts.b2100(q))
      let b2210 = my.removeBrackets(q.b2210 || 0)
      let b2220 = my.removeBrackets(q.b2220 || 0)

      if (b2210 < 0) {
        b2210 = b2210 * -1
      }
      if (b2220 < 0) {
        b2220 = b2220 * -1
      }

      return b2100 - b2210 - b2220
    }

    // Прибыль (убыток) до налогообложения
    ts.b2300 = (q) => {
      q = q || {}

      const b2200 = my.removeBrackets(ts.b2200(q))
      const b2310 = my.removeBrackets(q.b2310 || 0)
      const b2320 = my.removeBrackets(q.b2320 || 0)
      let b2330 = my.removeBrackets(q.b2330 || 0)
      const b2340 = my.removeBrackets(q.b2340 || 0)
      let b2350 = my.removeBrackets(q.b2350 || 0)

      if (b2330 < 0) {
        b2330 = b2330 * -1
      }
      if (b2350 < 0) {
        b2350 = b2350 * -1
      }

      return b2200 + b2310 + b2320 - b2330 + b2340 - b2350
    }

    // Чистая прибыль (убыток) отчетного периода
    ts.b2400 = (q) => {
      q = q || {}

      if (my.isOsn() || my.isUsnBalance()) {
        return (
          my.removeBrackets(ts.b2300(q)) +
          my.removeBrackets(q.b2450 || 0) +
          my.removeBrackets(q.b2430 || 0) +
          my.removeBrackets(q.b2410 || 0) +
          my.removeBrackets(q.b2460 || 0)
        )
      }
      if (my.isUsn6Kudir()) {
        return my.removeBrackets(q.b2110 || 0) * 0.94
      }
      if (my.isUsn15Kudir()) {
        return (
          my.removeBrackets(q.b2110 || 0) -
          my.removeBrackets(q.b2210 || 0)
        ) * 0.85
      }
    }

    ts.notEqual = (f1, f2, q) => {
      q = q || 0
      return ts[f1](q) !== ts[f2](q)
    }

    my.total = ts
  }

  my.isOsn = () =>
    $scope.model &&
    $scope.model.taxationTypeRefId === 'osn'

  my.isUsnBalance = () =>
    $scope.model &&
    $scope.model.taxationTypeRefId === 'usn' &&
    $scope.model.taxationAccountingTypeRefId === 'f1f2'

  my.isUsnKudir = () =>
    $scope.model &&
    $scope.model.taxationTypeRefId === 'usn' &&
    $scope.model.taxationAccountingTypeRefId === 'kudir'

  my.isUsn6Kudir = () =>
    $scope.model &&
    my.isUsnKudir() &&
    $scope.model.taxationUSNTypeRefId === 'usn6'

  my.isUsn15Kudir = () => 
    $scope.model &&
    my.isUsnKudir() &&
    $scope.model.taxationUSNTypeRefId === 'usn15'

  const updateModelCalc = (force) => {
    const model = $scope.model
    if (!model || !model.periods) {
      return
    }

    const isOsn = model.taxationTypeRefId === 'osn'
    const isUsnBalance = model.taxationTypeRefId === 'usn' && model.taxationAccountingTypeRefId === 'f1f2'
    const isUsnKudir = model.taxationTypeRefId === 'usn' && model.taxationAccountingTypeRefId === 'kudir'
    const isUsn6Kudir = isUsnKudir && model.taxationUSNTypeRefId === 'usn6'
    const isUsn15Kudir = isUsnKudir && model.taxationUSNTypeRefId === 'usn15'

    // Fill totals
    const totals = isOsn || isUsnBalance
      ? ['b1100', 'b1200', 'b1600', 'b1300', 'b1400', 'b1500', 'b1700', 'b2100', 'b2200', 'b2300', 'b2400']
      : isUsn6Kudir || isUsn15Kudir
        ? ['b2400']
        : []

    for (let dateMonth of Object.keys(model.periods)) {
      // Remove all unnecessary rows
      if (!force) {
        if (isUsn6Kudir) {
          model.periods[dateMonth] = {
            'b2110': model.periods[dateMonth].b2110
          }
        } else if (isUsn15Kudir) {
          model.periods[dateMonth] = {
            'b2110': model.periods[dateMonth].b2110,
            'b2210': model.periods[dateMonth].b2210
          }
        }
      }

      const period = model.periods[dateMonth]
      const periodState = my.state.periods[dateMonth]

      if (!force) {
        for (let code of totals) {
          const value = my.total[code](period)
          if (value) {
            period[code] = my.total[code](period)
            if (periodState) {
              periodState[code] = my.total[code](period)
            }
          } else {
            delete period[code]
            if (periodState) {
              delete periodState[code]
            }
          }
        }
      }

      for (let code of Object.keys(period)) {
        if (period[code] === null) {
          delete period[code]
          if (periodState) {
            delete periodState[code]
          }
        }
      }

      if (!Object.keys(period).length) {
        delete model.periods[dateMonth]
        if (periodState) {
          delete my.state.periods[dateMonth]
        }
      }

      Object.keys(period).forEach(code => {
        period[code] = my.positiveCodes.indexOf(code) > -1 && !force
          ? my.removeAll(period[code])
          : my.removeBrackets(period[code])

        if (my.negativeCodes.indexOf(code) > -1) {
          if (period[code] > 0) {
            period[code] = period[code] * -1
          }
        }
        my.state.periods = {
          ...my.state.periods,
          [dateMonth]: {
            ...my.state.periods[dateMonth],
            [code]: my.normilizeValue(period[code], code),
          },
        }
      })
      // console.log('model.periods', model.periods)
      // console.log('my.state.periods', my.state.periods)
    }

    my.autoRecognition = _.isEqual(my.periods, $scope.model.periods) || $scope.model.autoRecognition
    $scope.model.autoRecognition = my.autoRecognition

    // console.log('my.autoRecognition', my.autoRecognition)
    // console.log('my.periods', my.periods)
    // console.log('$scope.model.periods', $scope.model.periods)
    // console.log('$scope.model', $scope.model)
  }

  initSummaries()

  $scope.$watch('model', onModelChange)

  $scope.$watch('model.taxationTypeRefId', (newType) => {
    if (newType) updateModelCalc()
  })

  my.onValueChange = () => updateModelCalc()

  const onModelChange = () => {
    if (!$scope.model) return

    my.ref = {
      periods: [],
      taxationType: [
        { value: 'osn',  name: 'ОСН' },
        { value: 'usn', name: 'УСН' },
      ],
      taxationAccountingType: [
        { value: 'f1f2', name: 'Баланс' },
        { value: 'kudir', name: 'КУДиР' },
      ],
      taxationUSNType: [
        { value: 'usn6', name: 'доходы (6%)' },
        { value: 'usn15', name: 'доходы - расходы (15%)' },
      ]
    }

    initPeriods()

    if ($scope.model.config && $scope.model.config.taxationAccountingType) {
      my.ref.taxationAccountingType = []
      if ($scope.model.config.taxationAccountingType.f1f2) {
        my.ref.taxationAccountingType.push({ value: 'f1f2', name: 'Баланс' })
      }
      if ($scope.model.config.taxationAccountingType.kudir) {
        my.ref.taxationAccountingType.push({ value: 'kudir', name: 'КУДиР' })
      }
    }
  }

  my.hasDiff = (externalSourceBuhValue, modelValue) => Math.abs(externalSourceBuhValue) !== Math.abs(modelValue)

  my.onChange = (code, period) => {
    if (!$scope.model.periods[period]) {
      $scope.model.periods = {
        ...$scope.model.periods,
        [period]: {},
      }
    }
    $scope.model.periods[period][code] = my.state.externalSourceBuh[period].data[code]
    updateModelCalc(true)
  }

  my.onChangeAll = (period) => {
    const periodValues = my.state.externalSourceBuh[period].data || $scope.model.periods[period]
    for (const code in periodValues) {
      if (!$scope.model.periods[period]) {
        $scope.model.periods = {
          ...$scope.model.periods,
          [period]: {},
        }
      }
      $scope.model.periods[period][code] = my.state.externalSourceBuh[period].data[code]
    }
    updateModelCalc(true)
  }

  const clearFileInput = (el) => {
    const $el = $(el)
    $el.wrap('<form>').closest('form').get(0).reset()
    $el.unwrap()
  }

  my.onFileInputChange = (input) => {
    if (!input.files.length) return
    const file = [...input.files][0]
    clearFileInput(input)
    processFile(file)
  }

  my.onFileDrop = (files) => {
    if (!files || !files.length) return
    const file = [...files][0]
    processFile(file)
  }

  //---------------------------------------------------------------------------------------------
  const errorCodes = {
    'FILE_TOO_LARGE': 'Размер файла превышает максимально разрешённое значение',
    'PARSE_FAILED': 'Не удалось импортировать данные из контейнера ФНС',
    'NO_PERIODS': 'В этом контейнере ФНС нет ни одного из необходимых периодов отчётности. Укажите другой период в поле "Последний квартал" либо выберите другой файл',
    'WRONG_COMPANY': 'Отчётность в выбранном контейнере ФНС относится к другой компании',
    'READ_FILE_FAILED': 'Не удалось считать файл',
    'UPLOAD_FAILED': 'Не удалось сохранить файл',
    'UNEXPECTED': 'Произошла непредвиденная ошибка',
  }

  const processFile = (file) => {
    const taskId = getTaskId()
    const companyId = $scope.model.companyId
    const companyInn = $scope.model.companyInn
    const companyKpp = $scope.model.companyKpp
    const maxSizeInBytes = fzFilesConfig.getMaxSizeInBytes()
    const actualPeriods = my.ps
    const modelPeriods = $scope.model.periods
    const drafts = $scope.drafts

    my.isFinParsing = true
    my.isFinParsingError = false
    my.finParsingMessage = ''
    my.periods = null
    return checkFileSize(file, maxSizeInBytes) // Проверить размер файла
      .then(() => parseFile(file)) // Распарсить файл
      .then(periods => selectPeriods(periods, actualPeriods)) // Проверить наличие нужных периодов
      // .then(periods => validateInn(periods, companyInn, companyKpp)) // Проверить ИНН и КПП
      .then(periods => fillData(periods, modelPeriods)).then(() => updateModelCalc()) // Заполнить отчётность
      .then(() => readFile(file)) // Считать файл
      .then(content => uploadToDrafts(taskId, companyId, file.name, content, drafts)) // Загрузить файл в черновики
      .finally(() => my.isFinParsing = false)
      .then(() => {
        my.finParsingMessage = `Отчётность успешно импортирована из файла ${file.name}`
        my.periods = _.cloneDeep(modelPeriods)
        my.onValueChange()
      })
      .catch(e => {
        my.isFinParsingError = true
        my.finParsingMessage = errorCodes[e.errorCode || 'UNEXPECTED']
      })
  }

  const getTaskId = () => $element.closest('form').scope().camForm.taskId // HACK to get parent Form`s scope

  const checkFileSize = (file, maxSizeInBytes) => {
    if (file.size > maxSizeInBytes) {
      const err = new Error("File size too large")
      err.errorCode = 'FILE_TOO_LARGE'
      return $q.reject(err)
    }
    return $q.all([])
  }

  const parseFile = (file) => {
    const formData = new FormData()
    formData.append('file', file)
    return $http.post('/api/fin-report-parse-fns/parse/osn', formData, {
        transformRequest: angular.identity,
        headers: {
          'Content-Type': undefined
        }
      })
      .then(resp => (resp.data || {}).companyBuhs || [])
      .catch(e => {
        e.errorCode = 'PARSE_FAILED'
        return $q.reject(e)
      })
  }

  const selectPeriods = (periods, actualPeriods) => {
    const selectedPeriods = periods.filter(dataForPeriod =>
      actualPeriods.some(x => x != null && x.dateMonth === dataForPeriod.dateMonth)
    )
    if (!selectedPeriods.length) {
      const err = new Error('No suitable periods found')
      err.errorCode = 'NO_PERIODS'
      return $q.reject(err)
    }
    return $q.when(selectedPeriods)
  }

  const validateInn = (periods, companyInn, companyKpp) => {
    if (periods.some(p => p.inn !== companyInn || p.kpp !== companyKpp)) {
      const err = new Error('Wrong company')
      err.errorCode = 'WRONG_COMPANY'
      return $q.reject(err)
    }
    return $q.when(periods)
  }

  const fillData = (periods, modelPeriods) => {
    periods.forEach(dataForPeriod => {
      const period = {}
      for (let row of (dataForPeriod.buhRecords || [])) {
        const code = `b${row.code}`
        const amount = row.value * 100000
        period[code] = amount
      }
      modelPeriods[dataForPeriod.dateMonth] = period
    })
  }

  const readFile = (file) => {
    const d = $q.defer()

    const reader = new FileReader()
    reader.onerror = (e) => {
      e.errorCode = 'READ_FILE_FAILED'
      d.reject(e)
    }

    reader.onload = (e) => {
      const cnt = e.target.result.substr(5).split('')
      const content = cnt[1].substr(7)
      d.resolve(content)
    }

    reader.readAsDataURL(file)

    return d.promise
  }

  const uploadToDrafts = (taskId, companyId, filename, content, drafts) => {
    const docType = 'finReportYear'
    const camundaApiBaseUrl = '/camunda/api/engine/engine/default'

    const draftName = `${docType}@${companyId}`
    const nextNum = getNextNum(draftName, drafts)
    const numSuffix = nextNum == 1 ? '' : `!${nextNum}`
    const varName = `$locFileDraft_${draftName}${numSuffix}`
    const url = `${camundaApiBaseUrl}/task/${taskId}/localVariables/${encodeURIComponent(varName)}`

    const data = {
      value: content,
      type: 'File',
      valueInfo: {
        filename: filename
      }
    }

    return $http.put(url, data).catch(e => {
      e.errorCode = 'UPLOAD_FAILED'
      return $q.reject(e)
    }).then(() => {
      if (drafts) {
        const draftFiles = angular.copy(drafts[draftName] || [])
        const draft = {
          name: filename,
          varName: varName
        }
        draftFiles.push(draft)
        drafts[draftName] = draftFiles
      }
    })
  }

  const getNextNum = (draftName, drafts) => {
    const draftFiles = (drafts || {})[draftName] || []
    if (!draftFiles.length) return 1
    const numbers = draftFiles.map(x => {
      const ps = x.varName.split('!')
      if (ps.length < 2) return 1
      return Number(ps[1])
    })
    return Math.max(...numbers) + 1
  }

  //---------------------------------------------------------------------------------------------

  if ($scope.model) {
    onModelChange()
  }
}]

export default ctrl