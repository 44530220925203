import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.data = angular.copy($modalParams.item);
    my.ref = $modalParams.ref;
    my.readonly = !!$modalParams.readonly;

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };
}];