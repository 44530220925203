const ctrl = ['$scope', 'fzSvcCryptoPro', '$http', '$element', '$q', function ($scope, fzSvcCryptoPro, $http, $element, $q) {
    const camundaApiBaseUrl = '/camunda/api/engine/engine/default';
    const DRAFT_PREFIX = "$locFileDraft_";
    const my = this;

    my.state = {
        isAllSigned: false,
        isLoading: false,
        status: 'initial',
        thumbprint: '',
        certList: null
    };

    if (document.getElementsByClassName('form-action modal-n').length) {
        document.getElementsByClassName('form-action modal-n')[0].style.display = 'none'
    }

    my.showCerts = function () {
        my.state.thumbprint = '';
        my.state.certList = null;
        my.state.isLoading = true;
        fzSvcCryptoPro.getCertList().then(function (res) {
            my.state.certList = res;
            my.state.status = 'selectCert';
        }).catch(function (ex) {
            alert(ex.message || (ex.data ? ex.data.message : ex));
        }).finally(() => {
            my.state.isLoading = false;
        });
    };

    my.signAll = function () {
        my.state.isLoading = true;
        let ds = $q.all([]);
        const fileInfos = getFilesToSign();
        for (let fileInfo of fileInfos) {
            if (isFileSigned(fileInfo)) {
                continue;
            }

            ds = ds.then(() => sign(fileInfo));
        }

        ds.then(() => {
            my.state.status = 'done';
            $scope.onAccept();
        }).catch(err => {
            console.log(err);
            my.state.status = 'failed';
        }).finally(() => {
            my.state.isLoading = false;
        });
    };

    my.isAllSigned = function () {
        if (!$scope.model || !$scope.drafts) return false;
        const fileInfos = getFilesToSign();
        return fileInfos.every(isFileSigned);
    }

    function isFileSigned(x) {
        // Пропускаем только если имя файла оканчивается на '.sig'
        // Если имя файла не оканчивается на '.sig', значит его прислали на переподписание
        const isSigned = x.file.name.endsWith('.sig');
        return isSigned
            && $scope.drafts[x.draftName]
            && $scope.drafts[x.draftName].some(df => df.varName === x.draftVarName);
    }

    function getFilesToSign() {
        return $scope.model.entities.flatMap(entity => Object.values(entity.docs).flatMap(doc => doc.files.map((file, i) => ({
            draftVarName: `${DRAFT_PREFIX}${doc.docType}@${entity.entityId}${i ? `!${i + 1}` : ''}`,
            draftName: `${doc.docType}@${entity.entityId}`,
            file
        }))));
    }

    function arrayBufferToBase64(buffer) {
        let binStr = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binStr += String.fromCharCode(bytes[i]);
        }
        return btoa(binStr);
    }

    function getContent(href) {
        return $http({
            method: 'GET',
            url: href,
            responseType: 'arraybuffer'
        }).then(res => arrayBufferToBase64(res.data));
    }

    function getTaskId() {
        if (!my.state.taskId) {
            my.state.taskId = $element.closest('form').scope().camForm.taskId;
        }
        return my.state.taskId;
    }

    function uploadFile(fileInfo) {
        const data = {
            value: fileInfo.content,
            type: 'File',
            valueInfo: {
                filename: fileInfo.filename
            }
        }

        return $http.put(`${camundaApiBaseUrl}/task/${getTaskId()}/localVariables/${fileInfo.varName}`, data);
    }

    function sign(x) {
        const isSigned = x.file.name.endsWith('.sig');

        return getContent(x.file.href).then(function (dataToSign) {
            if (isSigned) {
                return fzSvcCryptoPro.coSign(my.state.thumbprint, dataToSign, false);
            } else {
                return fzSvcCryptoPro.sign(my.state.thumbprint, dataToSign, false);
            }
        }).then(function (s) {
            const fileInfo = {
                content: s,
                filename: isSigned ? x.file.name : x.file.name + '.sig',
                varName: x.draftVarName
            };
            return uploadFile(fileInfo);
        }).then(() => {
            const drafts = $scope.drafts;
            if (drafts) {
                const draftFiles = angular.copy(drafts[x.draftName] || []);
                const draft = {
                    name: isSigned ? x.file.name : x.file.name + '.sig',
                    varName: x.draftVarName,
                    href: `${camundaApiBaseUrl}/task/${getTaskId()}/localVariables/${encodeURIComponent(x.draftVarName)}/data`
                };
                draftFiles.push(draft);
                drafts[x.draftName] = draftFiles;
            }
        });
    }

    my.getDecisionText = function(decisionCode) {
        return $scope.decision.options.find((option) => option.code === decisionCode).text
    }
}];

export default ctrl;
