export function mapServiceCustomerToModel(data, bankGuaranteeType) {
  let bgAmount = null;
  if (bankGuaranteeType === 'execution') bgAmount = data.contractGuaranteeAmount;
  if (bankGuaranteeType === 'participation') bgAmount = data.applicationGuaranteeAmount;
  return {
    "inn": data.inn,
    "ogrn": data.ogrn,
    "displayName": data.fullName,
    "deliveryPlace": data.deliveryPlace,
    "appAmount": rubToKopeck(data.applicationGuaranteeAmount),
    "conAmount": rubToKopeck(data.contractGuaranteeAmount),
    "bgAmount": rubToKopeck(bgAmount),
    "contract": {
      "isConcluded": false
    },
    "customerRegNum": data.regNum
  }
}

export const round = (value, decimals = 2) =>
    Number(Math.round(Number(Number(value).toFixed(3)) + 'e' + decimals) + 'e-' + decimals)

export const rubToKopeck = (value) => value == null
    ? null
    : parseInt(round(value * 100))

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export function calcDuration(start, end) {
  if (!start || !end) return null;
  if (start > end) return null;
  const a = new Date(start);
  const b = new Date(end);

  return dateDiffInDays(a, b) + 1;
};

export function mapServiceProductToModel(product) {
  return {
    okpdCode: product.classifier.okpd2.code,
    okpdName: product.classifier.okpd2.name
  }
}

export function mapServiceLotToModel(lot, bankGuaranteeType) {
  const products = Object.values(lot.products.map(mapServiceProductToModel).reduce((cache, item) => {
    if (!cache[item.okpdCode]) {
      cache[item.okpdCode] = item;
    }
    return cache;
  }, {}));
  if (!products.length) products.push({});

  const ikzList = lot.ikzList.filter(x => !!x);
  if (!ikzList.length) ikzList.push("");

  return {
    "lotNumber": '' + lot.number,
    "lotSubject": lot.subject,
    "startAmount": rubToKopeck(lot.maxAmount),
    "finalAmount": null,
    "lotAssessmentProtocol": {
      "protocolName": null,
      "protocolNumber": null,
      "protocolDate": null
    },
    "ikzList": ikzList,
    "products": products,
    "contractConditions": {
      "prepaymentExists": false,
      "prepaymentAmount": null,
      "isRequiredIndisputableDebiting": false,
      "isIncludedForfeit": false,
      "isRequiredSecurityForGuaranteePeriod": false,
      "securityForGuaranteePeriodDays": null
    },
    "beneficiaries": lot.customers.map(x => mapServiceCustomerToModel(x, bankGuaranteeType))
  }
}

export function today() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
